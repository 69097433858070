import React, { useState } from 'react';
import logo from '../../Assets/images/logo.png';
import pattern from '../../Assets/images/pattern.png';
import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const loc = useLocation();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const headerStyle = {
        position: 'relative',
        zIndex: 1,
    };

    const pseudoElementStyle = {
        content: ' ',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.35,
        zIndex: -1,
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
    };

    return (
        <div className={`select_none ${isMobileMenuOpen ? 'pof' : ''}`}>
            <header style={headerStyle}>
                <div style={pseudoElementStyle}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-6 col-xs-5 zi_pymary">
                            <Link className="header__logo" to="/">
                                <img className="header__logo__full" src={logo} alt="Дари еду!" />
                            </Link>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-7">
                            <div className="flenx">
                            <div className={`mobile-menu-icon ${!isMobileMenuOpen ? '' : 'active'}`} onClick={toggleMobileMenu}>
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                            </div>
                            <div className={`modal_dev mobile-menu-open ${isMobileMenuOpen ? 'active' : ''}`}>
                              <ul className={`header__menu_mobile no_list_style no_decor`}>
                                  <li onClick={toggleMobileMenu} className={`${loc.pathname === "/" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/">Главная</Link></li>
                                  <li onClick={toggleMobileMenu} className={`${loc.pathname === "/freelanch" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/freelanch">Бесплатные обеды</Link></li>
                                  <li onClick={toggleMobileMenu} className={`${loc.pathname === "/about" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/about">О нас</Link></li>
                                  <li onClick={toggleMobileMenu} className={`${loc.pathname === "/help" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/help">Помочь проекту</Link></li>
                                  <li onClick={toggleMobileMenu} className={`${loc.pathname === "/report" || loc.pathname === "/document" || loc.pathname === "/photo" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/report">Отчёты</Link></li>
                                  <li onClick={toggleMobileMenu} className={`${loc.pathname === "/needhelp" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/needhelp">Мне нужна помощь</Link></li>
                              </ul>
                            </div>
                            
                              <ul className={`header__menu no_list_style no_decor`}>
                                  <li className={`${loc.pathname === "/" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/">Главная</Link></li>
                                  <li className={`${loc.pathname === "/freelanch" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/freelanch">Бесплатные обеды</Link></li>
                                  <li className={`${loc.pathname === "/about" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/about">О нас</Link></li>
                                  <li className={`${loc.pathname === "/help" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/help">Помочь проекту</Link></li>
                                  <li className={`${loc.pathname === "/report" || loc.pathname === "/document" || loc.pathname === "/photo" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/report">Отчёты</Link></li>
                                  <li className={`${loc.pathname === "/needhelp" ? "header__menu__item active" : "header__menu__item"}`}><Link to="/needhelp">Мне нужна помощь</Link></li>
                              </ul>
                            
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
