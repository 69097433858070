import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Contacts = () => {

    const [contacts, setContacts] = useState([]); 


    useEffect(() => {
      const accessToken = process.env.REACT_APP_API_TOKEN;
    
      axios
        .get('https://back.frigidercomunitar.md/information/contacts/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
            setContacts(response.data);
        })
        .catch(error => {
          console.error('Ошибка при получении контактов:', error);
        });
    
    }, []);

  return (
    <div className="test_block">
        <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3 className="main-content__main-products desktop-title tac">Команда</h3>
              </div>
                  {Array.isArray(contacts) && contacts.length > 0 && contacts.map(contact => (
                  <div key={contact.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <div class="main-about__team-box__person no_decor"> 
                          <div class="main-about__team-box__photo">
                              <img src={contact.image} alt="" />
                          </div> 
                          <div class="main-about__team-box__person-info"> 
                          <p class="main-about__team-box__person-name">{contact.title}</p> 
                          <p class="main-about__team-box__person-position">{contact.detail}</p> 
                          <div className="main_about__team_box__person_contacts">
                          {contact.phone ?(
                            <a href={`tel:${contact.phone}`} class="main-about__team-box__person-mail">{contact.phone}</a>
                          ):""}
                          <a href={`mailto:${contact.email}`} class="main-about__team-box__person-mail">{contact.email}</a>
                          {contact.tipe_social.name && contact.tipe_social.name==="Facebook" ? (
                            <a class="main-about__team-box__facebook-link select_none" rel="noreferrer" href={contact.link} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="#ffffff" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0 -48-48z"/></svg> 
                            facebook
                          </a> 
                          ):""}
                          </div>
                          
                          </div> 
                      </div>
                  </div>
                  ))}
            </div>
        </div>
    </div>
  )
}

export default Contacts