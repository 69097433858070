import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ReportDocument = () => {

    const [documents, setDocuments] = useState([]); 


    useEffect(() => {
      const accessToken = process.env.REACT_APP_API_TOKEN;
    
      axios
        .get('https://back.frigidercomunitar.md/raport/document/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
            setDocuments(response.data);
        })
        .catch(error => {
          console.error('Ошибка при получении категорий:', error);
        });
    
    }, []);

  return (
    <div className="test_block">
        <div className="container">
            <div className="row no_decor">
              <div className="col-lg-12 col-md-12">
                <h3 className="main-content__main-products desktop-title tac">Отчёты за год</h3>
              </div>
                  {Array.isArray(documents) && documents.length > 0 && documents.map(document => (
                  <div key={document.id} className="col-lg-4">
                      <a href={document.file}>
                      <div class="main-about__team-box__persone no_decor"> 
                          <div class="main-about__team-box__photo">
                              <img src={document.image} alt="" />
                          </div> 
                          <div class="main-about__team-box__person-info"> 
                          <p class="main-about__team-box__person-name">{document.title}</p> 
                          </div> 
                      </div>
                      </a>
                  </div>
                  ))}
            </div>
        </div>
    </div>
  )
}

export default ReportDocument