import React from 'react'

import mother from '../../Assets/images/static/01.svg';
import pension from '../../Assets/images/static/02.svg';
import one from '../../Assets/images/static/03.svg';
import ins from '../../Assets/images/static/04.svg';

const Howhelp = () => {
  return (
    <div>
        <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="contain-infor">
                    <h2>Кому мы помогаем</h2>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-help">
                        <img src={mother} alt="" />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-help">
                        <img src={pension} alt="" />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-help">
                        <img src={one} alt="" />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-help">
                        <img src={ins} alt="" />
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Howhelp