import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Viewimage from './Component/Viewimage';
import PopupCart from '../component/PopupCart';
const Freelanch = () => {

    const [products, setProducts] = useState([]); 


    useEffect(() => {
      const accessToken = process.env.REACT_APP_API_TOKEN;
    
      axios
        .get('https://back.frigidercomunitar.md/freelanch/meniu/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
          setProducts(response.data);
        })
        .catch(error => {
          console.error('Ошибка при получении категорий:', error);
        });
    
    }, []);
  
    
  return (
    <div className="test_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3 className="main-content__main-products desktop-title tac"> ПОРАДУЙ ОБЕДОМ УЖЕ СЕГОДНЯ!</h3>
              <div className="desktop-content">
                  <p>Целевая деятельность нашей организации направлено на удовлетворение необходимой потребности человека в регулярном, здоровом и свежем питании. Наши благополучатели это социально-незащищенные категории граждан: одинокие малоимущие пенсионеры, многодетные семьи, люди, попавшие в трудную жизненную ситуацию. Мы готовим и кормим голодных в трех населенных пунктах Молдовы, ежемесячно более 750 порций обедов для нуждающихся людей. Комплексные обеды (первое, второе блюдо, салат, десерт и хлеб; чай и кофе со сладостями).</p>     
                  <p>Мы не в силах решить проблему бедности или голода, но наша работа позволит немного сгладить негативные последствия и уменьшить уровень социальной напряженности в обществе.</p>   
              </div>
              <h3 className="main-content__main-products desktop-title tac">Сделать пожертвование</h3>
            </div>
          </div>
          <div className="row block_center">
            {Array.isArray(products) && products.length > 0 && products.map(product => (
                <div key={product.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="main-content__product product-box">
                  <div className="">
                  <div className="main-content__product__image"  itemprop="image">
                    <img src={product.image} alt="" />
                  </div>
                  <p className="main-content__product__name" itemprop="name">{product.title}</p>
                  <p className="main-content__product__description">{product.detail}</p>
                  </div>
                  <div className="main-content__product__to-cart-box" itemprop="offers" >
                    <span className="main-content__product__price"><span className="product-total-amount" itemprop="price">{product.count}</span> <span></span>MDL</span>
                    <PopupCart title={product.title} count={product.count} />
                  </div>
                </div>
              </div>
              ))}


          </div>
          <h3 className="main-content__main-products desktop-title tac">Наши обеды</h3>
          <Viewimage />
        </div>
      </div>
  )
}

export default Freelanch