import React from 'react';
import './Assets/css/index.css';
import './Assets/css/flexboxgrid.css';
import Header from './models/component/Header';
import Footer from './models/component/Footer';
import Homepage from './models/home/Homepage';
import Freelanch from './models/freelanch/Freelanch';
import About from './models/about/About';
import Helpproject from './models/helpproject/Helpproject';
import { Routes, Route,} from "react-router-dom";
import Needhelp from './models/needhelp/Needhelp';
import Report from './models/report/Report';
import Contacts from './models/contacts/Contacts';
import ReportDocument from './models/report/ReportDocument';
import Parteners from './models/parteners/Parteners';
import Politic from './models/politic/Politic';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Donate from './models/Donate/Donate';
import ReportPhoto from './models/report/ReportPhoto';
import Smi from './models/smi/Smi';
import Paynet from './models/component/Paynet';
function App() {

  return (
    <div>
      <Header />
      
      <Routes>
            <Route exact  path='/'  element={<Homepage />} />
            <Route path='/freelanch'  element={<Freelanch/>} />
            <Route path='/about'  element={<About/>} />
            <Route path='/help'  element={<Helpproject/>} />
            <Route path='/needhelp'  element={<Needhelp/>} />
            <Route path='/report'  element={<Report/>} />
            <Route path='/contacts'  element={<Contacts/>} />
            <Route path='/document'  element={<ReportDocument/>} />
            <Route path='/parteners'  element={<Parteners/>} />
            <Route path='/politic'  element={<Politic/>} />
            <Route path='/donate'  element={<Donate/>} />
            <Route path='/photo'  element={<ReportPhoto/>} />
            <Route path='/formedia'  element={<Smi/>} />
            <Route path='/pay'  element={<Paynet/>} />
        </Routes>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
