import React, { useEffect } from 'react';

const RedirectPaynet = ({ paymentData }) => {
  useEffect(() => {
    // Ставим таймер для отправки формы, чтобы форма отправилась после рендеринга компонента
    const timer = setTimeout(() => {
      document.getElementById('paynetForm').submit();
    }, 1); // Устанавливаем таймаут 1 секунда

    return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
  }, []); // Пустой массив зависимостей, чтобы эффект сработал только один раз

  return (
    <form id="paynetForm" method="POST" action="https://paynet.md/acquiring/getecom">
      <input type="hidden" name="operation" value={paymentData.PaymentId} />
      <input type="hidden" name="ExpiryDate" value={paymentData.ExpiryDate} />
      <input type="hidden" name="Signature" value={paymentData.Signature} />
      <input type="hidden" name="LinkUrlSucces" value="https://frigidercomunitar.md/"/>
      <input type="hidden" name="LinkUrlCancel" value="https://frigidercomunitar.md/"/>
      <input type="hidden" name="Lang" value="ru" />
    </form>
  );
};

export default RedirectPaynet;
