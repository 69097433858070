import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import {  toast } from 'react-toastify';
// import ReCAPTCHA from "react-google-recaptcha";
const Needhelp = () => {
  const [formData, setFormData] = useState({
    surname: '',
    name: '',
    phone: '',
    category: '',
    city: '',
    region: '',
    address: '',
    email: '',
    detail: '',
    image: null, 
  });

  const [image, setImage] = useState(null);
  const [check, setCheck] = useState(false);
  const accessToken = process.env.REACT_APP_API_TOKEN;

  const handleChange = async (e) => {
    const { name, value } = e.target;
    await setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!check) {
      console.error('Please accept the terms and conditions.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('image', formData.image);
    formDataToSend.append('surname', formData.surname);
    formDataToSend.append('detail', formData.detail);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('category', formData.category);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('region', formData.region);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('category', formData.category);
    formDataToSend.append('email', formData.email);

    try {
      await axios.post('https://back.frigidercomunitar.md/needhelp/listhelp/', formDataToSend, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      showNotification('Сообщение было успешно отправленно:', 'success');

      // Очищаем данные формы
      setFormData({
        surname: '',
        name: '',
        phone: '',
        category: '',
        city: '',
        region: '',
        address: '',
        email: '',
        detail: '',
        image: null,
      });
      setImage(null);
      setCheck(false);

    } catch (error) {
      showNotification('Что-то пошло не так:', 'error');
    }

  };

  const handleChangeImage = (e) => {
    e.preventDefault();
    const updatedFormData = { ...formData };

    if (e.target.files && e.target.files[0]) {
      const imageUrl = URL.createObjectURL(e.target.files[0]);

      if (imageUrl !== image) {
        setImage(imageUrl);
        updatedFormData.image = e.target.files[0];
      }
    } else {
      setImage(null);
      updatedFormData.image = null;
    }

    setFormData(updatedFormData);
  };


  const showNotification = (message, type) => {
    toast(message, { type });
  };


  const handleCheckChange = () => {
    setCheck(!check); 
  };
  const handleChangeTest = (e)=>{
    const button = e.target;
    button.disabled = true;
    handleSubmit(e);
    setTimeout(() => {
      button.disabled = false;
    }, 1000);
  };


  return (
    <div className="test_block">
       <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3 className="main-content__main-products desktop-title tac">Мне нужна помощь</h3>
            <div className="desktop-content tac"><p>Если вам нужна помощь, вы можете отправить нам заявку. В скором времени мы с Вами свяжемся.</p></div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-lg-offset-2 col-md-offset-2 ">
              <form className="style-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <label>
                    <span>Фамилия: <span className="red">*</span></span>
                    <input type="text" name="surname" value={formData.surname} onChange={handleChange} required />
                  </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Имя: <span className="red">*</span></span>
                      <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Номер телефона: <span className="red">*</span></span>
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Категория: <span className="red">*</span></span>
                      <input type="text" name="category" value={formData.category} onChange={handleChange} required />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Ваш город: <span className="red">*</span></span>
                      <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Район:</span>
                      <input type="text" name="region" value={formData.region} onChange={handleChange} />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Email:</span>
                      <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      <span>Адрес:</span>
                      <input type="text" name="address" value={formData.address} onChange={handleChange} />
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>
                      <span>О себе:</span>
                      <textarea cols="40" rows="10" name="detail" value={formData.detail} onChange={handleChange}></textarea>
                    </label>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="style_upload_img">
                      <span>Прикрепите ваше фото: <span className="red">*</span></span>
                      <div className='style_logo_upload' onClick={() => document.querySelector(".input-image-field").click()}>
                      <input
                        type="file"
                        name="logo"
                        accept="image/*"
                        className='input-image-field'
                        hidden
                        onChange={(e) => handleChangeImage(e)}
                      />
                      {image ?
                        <img src={image}  alt='imglogo'  />
                        :
                        <FontAwesomeIcon icon={faCloudUploadAlt} style={{color: "#0db97a",}} />
                      }
                    </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="select_none">
                    <div className={`main_cart__offert__action ${check ? 'active' : ''}`}>
                      <input
                        id="accept-offer-conf"
                        type="checkbox"
                        className="main-cart__offert__action__checkbox set-cart-offert"
                        name="accept-offer-conf"
                        required=""
                        onChange={handleCheckChange}
                        checked={check}
                      />
                      <label htmlFor="accept-offer-conf" className="main_cart__offert__action__label">
                        Я соглашаюсь с{' '}
                        <Link className="main-registration__accepts__link" to="/politic">
                          Политикой конфиденциальности
                        </Link>
                      </label>
                    </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                  </div>
                  
                  <div className="input-row select_none"> 
                    <button type="submit" className="but-green" onClick={handleChangeTest}>Отправить</button> 
                  </div>
                </div>
              </form>
            </div>
        </div>
       </div>
    </div>
  );
};

export default Needhelp;
