import React from 'react'
import Nabor from '../../Assets/images/don/listeat.jpg';
import Paynet from '../../Assets/images/don/donate.jpg';
import Check from '../../Assets/images/don/document.jpg';
import Howhelp from '../component/Howhelp';
import Contrate from '../../Assets/file/contract.pdf';
import { Link } from "react-router-dom";
const Helpproject = () => {
  return (
    <div className="test_block">
        <div className="container no_decor">
            <div className="row">
                <div className="col-lg-12">
                  <div className="help-method ">
                  <blockquote>
                  <p>Чтобы поверить в добро, надо начать делать его.</p>
                  <cite> Лев Толстой.</cite>
                  </blockquote>
                  <p>Привет! Как дела? У меня проблема.<br/> Это самая слышимая фраза в жизни человека, работающего в благотворительном фонде. Тут даже без обид, ведь зачастую, когда кто-то сталкивается с бедой, чаще всего не знает, куда ему бежать, куда податься. </p>
                  <p>      Этому не учат нас в школе, а стереотипные взгляды на оказание и получение благотворительной помощи выглядят для людей как преграды. </p>
                  <p>Множество семей тратит на продукты половину своего бюджета и часто стоят перед выбором – еда или оплата счетов. Cистемный благотворительный сбор продуктов в Молдове редкое явление. Количество организаций, которые решают данную проблему – ограничено, а государственной поддержки малоимущим семьям не хватает.</p>
                  </div>

                </div>
                <div className="col-lg-12">
                  <div className="contain-infor">
                    <h2>Пожертвовать</h2>
                  </div>
                </div>
                <div className="col-lg-4 mb2r">
                  <Link to="/">
                    <div className="contain-link">
                      <img src={ Nabor } alt="" />
                      <h3 class="main-donate__variables__title">Купить продукты</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 mb2r">
                  <Link to="/donate" >
                    <div className="contain-link">
                      <img src={ Paynet } alt="" />
                      <h3 class="main-donate__variables__title">Разовое пожертвование</h3>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 mb2r">
                  <a href={ Contrate }>
                    <div className="contain-link">
                      <img src={ Check } alt="" />
                      <h3 class="main-donate__variables__title">Реквизиты и договор для юридических лиц</h3>
                    </div>
                  </a>
                </div>
            </div>
            <Howhelp />
        </div>
    </div>
  )
}

export default Helpproject