import React from 'react'
import OneDonation from '../onedonation/OneDonation'

const Donate = () => {
  return (
    <div className='test_block'>
        <OneDonation />
    </div>
  )
}

export default Donate