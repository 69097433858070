import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Carousel, { Modal, ModalGateway } from 'react-images';
import '@splidejs/react-splide/css';
import axios from 'axios';
const ImageViewer = () => {

    const [products, setProducts] = useState([]);

    useEffect(() => {
      const accessToken = process.env.REACT_APP_API_TOKEN;
  
      axios
        .get('https://back.frigidercomunitar.md/freelanch/image-lanch/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
          setProducts(response.data);
        })
        .catch(error => {
          console.error('Ошибка при получении категорий:', error);
        });
    }, []);
  
    const photos = products.map(item => ({
      src: item.image,
      width: 3,
      height: 2,
    }));
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="horizontal-gallery-container">
    <Splide
      options={{
        perPage: 3,
        rewind: true,
        gap: '1rem',
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
      }}
    >
      {products.map((item, index) => (
        <SplideSlide key={index}>
          <img
            src={item.image}
            alt={`${index}`}
            onClick={(event) => openLightbox(event, { photo: item, index })}
          />
        </SplideSlide>
      ))}
    </Splide>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(photo => ({
                ...photo,
                srcset: photo.srcSet,
                caption: photo.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default ImageViewer;
