import React from 'react';
import paypal from '../../Assets/images/pay/ppcom.svg';
import patreon from '../../Assets/images/pay/Patreon.svg';
// import Paynes from '../../Assets/images/don/donate.jpg';
// import Payne from '../../Assets/images/don/donat.jpg';
import PopupDonation from './PopupDonation';

const OneDonation = () => {

  // const images = [Paynes, Payne];
  // const [randomIndex, setRandomIndex] = useState(0);

  // useEffect(() => {
  //   setRandomIndex(Math.floor(Math.random() * images.length));
  // }, [images.length]);

    const handleDonatePaypal = () => {
        const paypalDonationUrl = 'https://www.paypal.com/paypalme/foodsharingchisinau';
        window.location.href = paypalDonationUrl;
      };
      const handleDonatePatreon = () => {
        const paypalDonationUrl = 'https://www.patreon.com/FrigiderComunitar/membership';
        window.location.href = paypalDonationUrl;
      };
      

  return (
    <div>
        <div className="container">
            <div className="row block_center">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 class="main-content__main-products desktop-title tac">Разовое пожертвование</h3>
                    <div class="desktop-content tac"><p>Вы можете помочь Благотворительному проекту Frigider Comunitar разовым пожертвованием.</p></div>
                </div>
            </div>
            <div className="row aic mb50">
              {/* <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-lg-offset-4 col-md-offset-4">
                    <div className="one_pays">
                      <img src={images[randomIndex]} alt="" />
                    </div>
                  </div>
                   */}

            </div>
            <div className="row">
            <div  className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                  <PopupDonation />
                </div>
                <div  className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                  <div onClick={handleDonatePaypal} className="main-content__product product_pay product-box paypal select_none">
                    <div className="">
                    <div className="main-content__product__image">
                      <img src={paypal} alt="" />
                    </div>
                    <p className="main-content__product__name" itemprop="name">PayPal™</p>
                    <p className="main-content__product__description">Международная компания, предоставляющая услуги онлайн-платежей и переводов средств через интернет</p>
                    </div>
                    <div className="main-content__product__to-cart-box" itemprop="offers" >
                      <span className="main-content__product__cart-button put-set-to-cart " id="add-to-cart">
                        <button className="btn-bay">Пожертвовать</button>
                      </span>
                    </div>
                  </div>
                </div>
                <div  className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                  <div onClick={handleDonatePatreon} className="main-content__product product_pay product-box pateon select_none">
                    <div className="">
                    <div className="main-content__product__image">
                      <img src={patreon} alt="" />
                    </div>
                    <p className="main-content__product__name" itemprop="name">Patreon</p>
                    <p className="main-content__product__description">Patreon - это платформа для финансирования творчества онлайн. Авторы получают поддержку от поклонников через ежемесячные пожертвования или подписки. Подписчики получают доступ к эксклюзивному контенту.</p>
                    </div>
                    <div className="main-content__product__to-cart-box" itemprop="offers" >
                      <span className="main-content__product__cart-button put-set-to-cart " id="add-to-cart">
                        <button className="btn-bay">Пожертвовать</button>
                      </span>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OneDonation