import React from 'react'
import Gallery from '../../Assets/images/gallery.svg';
import Library from '../../Assets/images/library.svg';
import { Link } from "react-router-dom";
const Report = () => {
  return (
    <div className="test_block no_decor">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 class="main-content__main-products desktop-title tac">Отчеты</h3>
                    <div class="desktop-content tac"><p>Здесь мы рассказываем и показываем как передаём продукты</p></div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-lg-offset-2 col-md-offset-2">
                    <Link to="/photo">
                        <div className="cont_lib">
                            <div className="">
                                <img src={ Gallery } alt="" />
                            </div>
                            <div className="tittle_report">
                                <h4>Фото отчёты</h4>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                    <Link to="/document" >
                        <div className="cont_lib">
                            <div className="">
                                <img src={ Library } alt="" />
                            </div>
                            <div className="tittle_report">
                                <h4>Отчёты за год</h4>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Report