import React from 'react'
import pattern from '../../Assets/images/pattern.png';
import logosmall from '../../Assets/images/logo-small.png';
import { Link, useLocation } from "react-router-dom";
const Footer = () => {
  const loc = useLocation();
    const headerStyle = {
        position: 'relative',
        zIndex: 1,
      };
      const pseudoElementStyle = {
        content: ' ',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.35,
        zIndex: -1,
        backgroundImage: `url(${pattern})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
      };

      
  return (
    <div>
      <footer style={headerStyle}>
      <div style={pseudoElementStyle}></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="contain_item">
                <Link className="footer__copyright__logo" to="/" >
                  <img src={logosmall} alt="Дари еду!"/>
                </Link>
                <div className="recviz">
                  <div>Asociatia Obsteasca</div>
                  <div>Frigider Comunitar</div>
                  <div>IDNO 1020620008348</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="contain_item">
                <ul className="footer__menu__block no_list_style no_decor">
                    <li className={`${loc.pathname === "/" ? "footer__menu__link active" : "footer__menu__link"}`}>
                        <Link data-retarget="click-link-contacts" to="/" >Перечень продуктов</Link>
                    </li>
                    <li className={`${loc.pathname === "/contacts" ? "footer__menu__link active" : "footer__menu__link"}`}>
                        <Link data-retarget="click-link-contacts" to="/contacts" >Контакты</Link>
                    </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="contain_item">
                <ul className="footer__menu__block no_list_style no_decor">
                    <li className={`${loc.pathname === "/parteners" ? "footer__menu__link active" : "footer__menu__link"}`}>
                      <Link data-retarget="click-link-contacts" to="/parteners" >Сотрудничество</Link>
                    </li>
                    <li className={`${loc.pathname === "/formedia" ? "footer__menu__link active" : "footer__menu__link"}`}>
                        <Link data-retarget="click-link-contacts" to="/formedia" >Для СМИ</Link>
                    </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="contain_item">
              <ul className="footer__social-links no_list_style no_decor">
                <li className="footer__menu__link"> <a href="https://www.facebook.com/frigidercomunitar" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="21" width="20" viewBox="0 0 448 512"><path fill="#3b71ce" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0 -48-48z"/></svg>Facebook</a> </li>
                <li className="footer__menu__link"> <a href="https://www.instagram.com/frigidercomunitar/" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="21" width="20" viewBox="0 0 448 512"><path fill="#db3c14" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>Instagram</a> </li>
                <li className="footer__menu__link"> <a href="tel:+37360254481" rel="noreferrer" target="_blank" ><svg xmlns="http://www.w3.org/2000/svg" height="19" width="17" viewBox="0 0 512 512"><path fill="#3090c0" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1 .6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"/></svg>+37360254481</a> </li> 
                <li className="footer__menu__link"> <a href="mailto:frigidercomunitar@gmail.com" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="19" width="19" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7 .3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2 .4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/></svg>frigidercomunitar@gmail.com</a> </li>
              </ul>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="politic_block">
              <Link className="main-registration__accepts__link" to="/politic" >Политика конфиденциальности</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer