import React, { useState, useEffect } from 'react';
import axios from 'axios';

import product from '../../Assets/images/static/1.svg';
import deposit from '../../Assets/images/static/2.svg';
import form from '../../Assets/images/static/3.svg';
import transfer from '../../Assets/images/static/4.svg';

import Howhelp from '../component/Howhelp';


const About = () => {

    const [socials, setSocials] = useState([]); 


    useEffect(() => {
      const accessToken = process.env.REACT_APP_API_TOKEN;
    
      axios
        .get('https://back.frigidercomunitar.md/about/social/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
            setSocials(response.data);
        })
        .catch(error => {
          console.error('Ошибка при получении категорий:', error);
        });
    
    }, []);


  return (
    <div className="test_block">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3 className="main-content__main-products desktop-title tac">О нас</h3>
                <div class="main-about__text">
                <p>
                Мы - первый в Молдове благотворительный продуктовый интернет-магазин продуктов питания. </p>

                <p>В основе — мировой опыт проекта Food Bank (в пер. с англ. «банк еды»), благодаря которому социально уязвимые слои населения вот уже 60 лет получают жизненно необходимые продукты питания. Мы хотим, чтобы социально уязвимые граждане Республики Молдова также были обеспечены базовым набором продуктов.
                </p>
                <p>
                С мая 2020 года Общественная Организация Frigider Comunitar начала спасать еду и продукты из магазинов, пекарней, крупных продуктовых маркетов. Каждый день магазины, кафе и производители выбрасывают тонны продуктов и готовых блюд, которые не были проданы вовремя и/или имеют незначительные дефекты. 
                </p>
                <p>
                Мы хотим, чтобы эта еда передавалась людям, а не попадала в мусорный бак. Наша организация выстроила логистику получения и передачи продуктов, и на сегодняшний день, еженедельно мы спасаем более 500 кг. продуктов питания и распределяем их среди нуждающихся. 
                </p>
                <p>
                Мы помогаем матерям-одиночкам, многодетным семьям, пенсионерам, инвалидам, одиноким пожилым людям, ветеранам ВОВ, а также просто людям, которые на данный момент оказались в сложной жизненной ситуации. 
                </p>
                <p>
                С сентября 2020 года в организацию были внесены изменения и мы стали готовить бесплатные вкусные обеды для нуждающихся как из спасенных продуктов, так и из закупленных на личные средства руководителей организации.
                </p>
                <p>
                Цель нашего проекта – доступная и повседневная благотворительность. </p>
                <p>
                Присоединяйтесь к нам!
                </p>
                    </div>
                </div>
                
                
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="contain-infor">
                    <h2>Логистика магазина <span>Frigider Comunitar</span></h2>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-service">
                        <img src={product} alt="" />
                        <div className="tittle-serv">Покупка продуктов</div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-service">
                        <img src={deposit} alt="" />
                        <div className="tittle-serv">Доставка на наш склад</div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-service">
                        <img src={form} alt="" />
                        <div className="tittle-serv">Формирование наборов</div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="contain-service">
                        <img src={transfer} alt="" />
                        <div className="tittle-serv">Передача продуктов нуждающимся лично в руки</div>
                    </div>
                </div>
            </div>
            <Howhelp />
            <div className="row">
                <div className="col-lg-12">
                    <div className="contain-infor">
                    <h2>Мы в социальных сетях</h2>
                    </div>
                </div>
                {Array.isArray(socials) && socials.length > 0 && socials.map(social => (
                <div key={social.id} className="col-lg-6">
                    <div class="main-about__team-box__person no_decor"> 
                        <div class="main-about__team-box__photo">
                            <img src={social.image} alt="" />
                        </div> 
                        <div class="main-about__team-box__person-info"> 
                        <p class="main-about__team-box__person-name">{social.title}</p> 
                        <p class="main-about__team-box__person-position">{social.detail}</p> 
                        <a class="main-about__team-box__facebook-link select_none" rel="noreferrer" href={social.link} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="#ffffff" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0 -48-48z"/></svg> facebook</a> 
                        </div> 
                    </div>
                </div>
                ))}
            </div>
        </div>

    </div>
  )
}

export default About