import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'; 
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ReportPhoto = () => {
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [images, setImages] = useState([]);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const accessToken = process.env.REACT_APP_API_TOKEN;

    useEffect(() => {
        axios.get('https://back.frigidercomunitar.md/raport/year/', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then(response => {
            setYears(response.data);
            setSelectedYear(response.data[0].name);
        })
        .catch(error => {
            console.error('Ошибка:', error);
        });
    }, [accessToken]);
    
    useEffect(() => {
        axios.get('https://back.frigidercomunitar.md/raport/month/', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then(response => {
            setMonths(response.data);
            setSelectedMonth(response.data[0].name);
        })
        .catch(error => {
            console.error('Ошибка:', error);
        });
    }, [accessToken]);
    
    useEffect(() => {
        axios.get(`https://back.frigidercomunitar.md/raport/api/gallery/filter/?name=${selectedMonth}&year=${selectedYear}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then(response => {
            console.log('Response data:', response.data);
            const galleryShots = response.data[0]?.gallery_shots || [];
            setImages(galleryShots);
        })
        .catch(error => {
            console.error('Ошибка:', error);
        });
    }, [selectedMonth, selectedYear, accessToken]);
    
    
    const handleYearChange = selectedOption => {
        setSelectedYear(selectedOption.value);
    };

    const handleMonthChange = selectedOption => {
        setSelectedMonth(selectedOption.value);
    };

    const openLightbox = index => {
        setCurrentImageIndex(index);
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    return (
        <div className='test_block'>
            <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 className="main-content__main-products desktop-title tac">Фото отчёты</h3>
                    <div className="desktop-content tac"><p>Здесь мы рассказываем и показываем как передаём продукты</p></div>
                </div>
                
            </div>
            <div className="row block_center">
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                    <div className='margin_select'>
                        <label>Выберите год:</label>
                        <Select
                            options={years.map(year => ({ value: year.name, label: year.name }))}
                            value={{ value: selectedYear, label: selectedYear }}
                            onChange={handleYearChange}
                        />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div className='margin_select'>
                        <label>Выберите месяц:</label>
                        <Select
                            options={months.map(month => ({ value: month.name, label: month.name }))}
                            value={{ value: selectedMonth, label: selectedMonth }}
                            onChange={handleMonthChange}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="block_margin"></div>
                </div>

                {images && images.map((image, index) => (
                    <div key={image.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12" onClick={() => openLightbox(index)}>
                        <div className="contain_image_report">
                            <img src={image.image_url} alt={image.title} />
                        </div>
                    </div>
                ))}

            </div>
            {lightboxIsOpen && (
                <Lightbox
                    mainSrc={images[currentImageIndex]?.image_url}
                    nextSrc={images[(currentImageIndex + 1) % images.length]?.image_url}
                    prevSrc={images[(currentImageIndex + images.length - 1) % images.length]?.image_url}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => setCurrentImageIndex((currentImageIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setCurrentImageIndex((currentImageIndex + 1) % images.length)}
                />
            )}
        </div>
            
        </div>
    );
    
};

export default ReportPhoto;
