import React from 'react'

const Politic = () => {
  return (
    <div className="test_block">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <div class="content main-content slim-view-port"> 
                    <h1>Политика конфиденциальности </h1> 
                    <p className='tright'>г. Кишинев</p> 
                    <p className='tright'>1 февраля 2021 год</p> 
                    <p>&nbsp;</p> 
                                
                    <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Asociatia Obsteasca Frigider Comunitar (далее — Фонд) может получить о Пользователе в процессе использования им веб-сайтов Фонда, расположенных на доменных именах https://frigidercomunitar.md/ (далее вместе – Сайт), а также при использовании связанного с Сайтом программного обеспечения.</p>

                    <p>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>

                    <p>1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>

                    <p>1.1.1. Администрация Сайта (далее – Администрация Сайта) – уполномоченные сотрудники по управлению сайтом, действующие от имени Asociatia Obsteasca Frigider Comunitar, которые организуют и (или) осуществляет обработку персональных данных в соответствии с целями обработки персональных данных и иными условиями, определенными Фондом, как описано ниже.</p>

                    <p>1.1.2. Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>

                    <p>1.1.3. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>

                    <p>1.1.4. Конфиденциальность персональных данных – обязательное для соблюдения оператором или иным, получившим доступ к персональным данным, лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>

                    <p>1.1.5. Пользователь Сайта (далее - Пользователь) – лицо, имеющее доступ к Сайту посредством сети Интернет и использующее Сайт.</p><p>

                    </p><p>1.1.6. Cookies – небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>

                    <p>1.1.7. IP-адрес – уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>

                    <p>2. ОБЩИЕ ПОЛОЖЕНИЯ</p>

                    <p>2.1. Использование Сайта Пользователем означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>

                    <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сайта.</p>

                    <p>2.3. Настоящая Политика конфиденциальности применяется только к Сайту и связанным с ним сервисам. Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>

                    <p>2.4. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем Сайта.</p>

                    <p>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>

                    <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Фонда и Администрации Сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации Сайта при регистрации на Сайте или при использовании связанных с ним сервисов.</p>

                    <p>3.2. Персональные данные, разрешенные к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем следующими путями:</p>

                    <p>Заполнения анкеты-формы регистрации пользователя;
                    Заполнения анкеты волонтера;
                    Заполнения форм для осуществления пожертвования;
                    Заполнения формы подписки на новости;
                    Заполнения формы обратной связи;
                    Заполнения иных анкет и анкет-форм регистрации, расположенных на Сайте и содержат:</p>

                    <p>3.2.1. Фамилию, имя, отчество Пользователя;

                    3.2.2. Контактный телефон Пользователя

                    3.2.3. Адрес электронной почты (e-mail);

                    3.2.4. Дату рождения;

                    3.2.5. Город и регион проживания.</p>

                    <p>3.3. Сайт защищает данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлены статистические скрипты системы:</p>

                    <p>IP адрес;
                    информация из cookies;
                    информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы) и операционной системе;
                    время доступа;
                    адрес страницы, на которой расположен рекламный блок;
                    реферер (адрес предыдущей страницы).
                    3.3.1. Отключение cookies может повлечь невозможность доступа к частям Сайта, требующим запоминания состояния пользователя (например, авторизации).</p>

                    <p>3.3.2. Сайт может осуществлять сбор статистики об IP-адресах своих посетителей. Данная информация используется в обезличенной форме с целью выявления и решения технических проблем.</p>

                    <p>3.4. Сайт и Фонд не осуществляют сбор и/или обработку каких-либо иных сведений, кроме указанных выше в настоящей Политике конфиденциальности, без получения на это прямого согласия субъектов соответствующих данных.</p>
                    <p>
                    4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
                    </p><p>
                    4.1. Персональные данные Пользователя Администрация Сайта может использовать в целях:
                    </p><p>
                    4.1.1. Идентификации Пользователя, зарегистрированного на Сайте, для предоставления релевантных Пользователю данных.
                    </p><p>
                    4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.
                    </p><p>
                    4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработки запросов и заявок от Пользователя.
                    </p><p>
                    4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
                    </p><p>
                    4.1.5. Подтверждения полноты персональных данных, предоставленных Пользователем.
                    </p><p>
                    4.1.6. Создания учетной записи для совершения действий на Сайте, если Пользователь дал согласие на создание учетной записи.
                    </p><p>
                    4.1.7 Регистрации Пользователя в качестве волонтера Фонда.
                    </p><p>
                    4.1.8. Уведомления Пользователя Сайта о состоянии его подписок и прочей информации.
                    </p><p>
                    4.1.9. Обработки и получения платежей.
                    </p><p>
                    4.1.10. Предоставления Пользователю клиентской поддержки при возникновении проблем, связанных с использованием Сайта.
                    </p><p>
                    4.1.11. Предоставления Пользователю с его согласия обновлений данных и специальных предложений, новостной рассылки и иных сведений от имени Сайта.
                    </p><p>
                    4.1.12 Улучшения качества веб-сайта путем анализа действия Пользователя на сайте.
                    </p><p>
                    5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                    </p><p>
                    5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока (но не дольше, чем этого требуют цели обработки персональных данных), любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
                    </p><p>
                    5.2. Пользователь соглашается с тем, что Администрация Сайта вправе передавать персональные данные третьим лицам, в частности курьерским службам, организациям почтовой связи, операторам электросвязи, исключительно в целях выполнения Фондом обязательств в рамках взаимодействия с Пользователем, оформленным на Сайте.
                    </p><p>
                    5.3. Пользователь соглашается с тем, что Фонд размещает на Сайте информацию о суммах пожертвований, полученных Фондом, c указанием фамилии, имени, отчества либо наименования организации Пользователя. Размещаемые на сайте данные Пользователя – физического лица не должны давать возможность для однозначной идентификации персональных данных третьими лицами. Пользователь вправе отказаться от указания каких-либо из указанных выше данных на Сайте.
                    </p><p>
                    5.4. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Республики Молдова только по основаниям и в порядке, установленном законодательством РМ.
                    </p><p>
                    5.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или разглашении персональных данных.
                    </p><p>
                    5.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                    </p><p>
                    5.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                    </p><p>
                    5.8. Согласие на обработку персональных данных действует до тех пор, пока Пользователь не отзовет его в письменном виде.
                    </p><p>
                    5.9. Фонд осуществляет обработку персональных данных Пользователей с использованием баз данных, находящихся на территории РМ.
                    </p><p>
                    6. ОБЯЗАТЕЛЬСТВА СТОРОН
                    </p><p>
                    6.1. Пользователь обязан:
                    </p><p>
                    6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
                    </p><p>
                    6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
                    </p><p>
                    6.2. Администрация Сайта обязана:
                    </p><p>
                    6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
                    </p><p>
                    6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, публикацию, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением подп. 5.2 настоящей Политики Конфиденциальности.
                    </p><p>
                    6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.
                    </p><p>
                    6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, а также уточнить или уничтожить соответствующие персональные данные, в случае выявления недостоверных персональных данных или неправомерных действий.
                    </p><p>
                    6.2.5. Предоставлять Пользователю по запросу информацию, касающуюся обработки его персональных данных, в соответствии с законодательством РМ.
                    </p><p>
                    7. ОТВЕТСТВЕННОСТЬ СТОРОН
                    </p><p>
                    7.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность в соответствии с законодательством РМ, за исключением случаев, предусмотренных подп. 5.2 и 7.2 настоящей Политики Конфиденциальности.
                    </p><p>
                    7.2. В случае утраты или разглашения Конфиденциальной информации Администрация Сайта не несет ответственности, если данная конфиденциальная информация:
                    </p><p>
                    7.2.1. Стала публичным достоянием до ее утраты или разглашения.
                    </p><p>
                    7.2.2. Была получена от третьей стороны до момента ее получения Администрацией сайта.
                    </p><p>
                    7.2.3. Была разглашена с согласия Пользователя.
                    </p><p>
                    8. РАЗРЕШЕНИЕ СПОРОВ
                    </p><p>
                    8.1. Пользователь и Фонд согласились, что в случае возникновения каких-либо разногласий относительно использования Сайта, они приложат взаимные усилия для разрешения таких разногласий в порядке переговоров после предоставления соответствующего уведомления стороной, которая посчитает, что ее права были нарушены.
                    </p><p>
                    8.2. При недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РМ.
                    </p><p>
                    8.3. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется действующее законодательство РМ.
                    </p><p>
                    9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
                    </p><p>
                    9.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
                    </p><p>
                    9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности
                    </p><p>
                    9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать:
                    </p><p>
                    Asociatia Obsteasca Frigider Comunitar, mun.Chisinau, str.Haltei 7
                    frigidercomunitar@gmail.com 
                    </p><p>
                    9.4. Действующая Политика конфиденциальности размещена на странице по адресу https://frigidercomunitar.md/politic </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Politic