import React, { useState } from 'react';
import Modal from 'react-modal';
import logo from '../../Assets/images/logo.png';
import paynet from '../../Assets/images/pay/logo-paynet.svg';
import Paynet from '../component/Paynet';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from "react-router-dom";

Modal.setAppElement('#root');

const PopupDonation = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const [price, setPrice] = useState(100);
    const [email, setEmail] = useState('');
    const [paymentData, setPaymentData] = useState(null);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setCheck(false);
        setPrice('');
        setEmail('');
    };

    const handleCheckChange = () => {
        setCheck(!check); 
    };

    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const showNotification = (message, type) => {
        toast(message, { type });
      };

    const handleSubmit = () => {

    };

    const accessToken = process.env.REACT_APP_API_TOKEN;

    const handleSendClick = async (e) => {
      e.preventDefault();
    
      if (!check) {
        console.error('Please accept the terms and conditions.');
        return;
      }
    
      const formDataToSend = new FormData();
      formDataToSend.append('title', "Разовое пожертвование");
      formDataToSend.append('count', price);
      formDataToSend.append('email', email);
    
      try {
        const response = await axios.post('https://back.frigidercomunitar.md/pay/payment/', formDataToSend, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });
  
        setPaymentData(response.data);
  
      
      } catch (error) {
        showNotification('Что-то пошло не так:', 'error');
      }
    };

    return (
        <div>
            {paymentData && <Paynet paymentData={paymentData} />}
            <div  onClick={openModal} className="main-content__product product_pay product-box paynet select_none">
                  <div className="">
                  <div className="main-content__product__image"  itemprop="image">
                    <img src={paynet} alt="" />
                  </div>
                  <p className="main-content__product__name" itemprop="name">Paynet</p>
                  <p className="main-content__product__description">Одна из крупнейших электронных платежных систем в Молдове - система Paynet</p>
                  </div>
                  <div className="main-content__product__to-cart-box" itemprop="offers" >
                    <span data-set="1" className="main-content__product__cart-button put-set-to-cart " id="add-to-cart">
                      <button className="btn-bay">Пожертвовать</button>
                    </span>
                  </div>
                </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Adaugă Utilizator"
                shouldCloseOnOverlayClick={false}
                className="stype_upload_popup popup_height_auto"
            >
                <div className="contain_modal">
                    <div className="modal-header">
                        <h3 className="modal-title">Для оплаты необходимо согласиться с условиями конфиденциальности</h3>
                        <button className="close" onClick={handleCloseModal}>×</button>
                    </div>
                    <div className="modal-body">
                        <div className="modal__politic">
                            <img style={{ maxHeight: '100px', maxWidth: '150px' }} src={logo} alt="Дари еду!" />
                            <p style={{ fontWeight: 'bold' }}>Разовое пожертвование</p>
                            <p className="modal_politic_description">Вы можете помочь Благотворительному проекту Frigider Comunitar разовым пожертвованием.</p>
                        </div>
                        <form className='form_styles' onSubmit={handleSubmit}>
                            <label htmlFor="price">Цена:</label>
                            <input type="number" min="1"  id="price" value={price} onChange={handlePriceChange} />

                            <label htmlFor="email">Электронная почта:</label>
                            <input type="email" id="email" value={email} onChange={handleEmailChange} />

                            <div className={`main_cart__offert__action  select_none ${check ? 'active' : ''}`}>
                                <input
                                    id="accept-offer-conf"
                                    type="checkbox"
                                    className="main-cart__offert__action__checkbox set-cart-offert"
                                    name="accept-offer-conf"
                                    required=""
                                    onChange={handleCheckChange}
                                    checked={check}
                                />
                                <label htmlFor="accept-offer-conf" className="main_cart__offert__action__label">
                                    Я соглашаюсь с{' '}
                                    <Link className="main-registration__accepts__link" to="/politic">
                                    Политикой конфиденциальности
                                    </Link>
                                </label>
                            </div>
                            <div class={`select_none ${check ? 'active' : ''}`}> 
                        <button type="submit" class="but-grey" onClick={handleSendClick}>Отправить</button> 
                    </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PopupDonation;
