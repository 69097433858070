import React from 'react'

const Parteners = () => {
  return (
    <div className="test_block">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <h3 className="main-content__main-products desktop-title">Сотрудничество</h3>
                    <div class="desktop-content">
                        <p>Благотворительная Организация «Frigider Comunitar» будет рада работу и сотрудничеству с рядом корпоративных партнеров. Каждое партнерство является уникальным и основывается на индивидуальных потребностях компании.</p>
                        <p>Мы также можем предложить Вам использовать одну или несколько из уже применяемых нашей организации форм сотрудничества.</p>
                    </div>
                    <div className="up_cntent">
                        <h4>•	Финансовое участие</h4>
                        <p>Вы можете внести одноразовое благотворительное пожертвование или договориться о постоянной поддержке деятельности нашей благотворительной организации. Кроме того, вы можете взять на себя оплату части административных расходов фонда, например, заработной платы одному из сотрудников (в данный момент все сотрудники фонда работают бесплатно). Мы не стремимся оставлять себе какой-либо процент от благотворительных пожертвований, поэтому Вы можете не перечисляя деньги на счет фонда напрямую оплатить счет на товары или услуги, необходимые нашим подопечным.</p>
                    </div>
                    <div className="up_cntent">
                        <h4>•	Помощь своей продукцией и услугами</h4>
                        <p>Передача продукции компании в помощь нуждающимся: продукты питания, сладости, крупы, консервы, предметы гигиены и бытовая химия, памперсы, игрушки, канцелярские принадлежности для учебы. Помощь организации: юридическая поддержка, разработка дизайна, печать информационных материалов, бухгалтерское сопровождение, оргтехника, мобильная связь.</p>
                    </div>
                    <div class="contain-infor">
                        <h3>Кто может быть партнёром «Frigider Comunitar!»?</h3>
                        <p>Производители продуктов питания и фермерские хозяйства;</p>
                        <p>Организации общественного питания (рестораны, кафе, пекарни, столовые);</p>
                        <p>Торговые сети и торговые центры; Компании практикующие интеллектуальное волонтёрство (маркетинг, дизайн, реклама, креатив, IT, экспертная оценка и др.)</p>
                        <p>Социально ориентированные НПО;</p>
                        <p>Волонтёрские сообщества.</p>
                        <p>Если у вас есть идеи для сотрудничества или желание помочь проекту, пожалуйста, <a className='green' href="mailto:frigidercomunitar@gmail.com"> свяжитесь с нами </a></p>
                    </div>

                </div>

            </div>
        </div>
    </div>
  )
}

export default Parteners