import React, { useState } from 'react';
import Modal from 'react-modal';
import logo from '../../Assets/images/logo.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Paynet from './Paynet';

Modal.setAppElement('#root');

const PopupCart = ({ title, count }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const [paymentData, setPaymentData] = useState(null);


  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setCheck(false);
  };

  const handleCheckChange = () => {
    setCheck(!check);
  };

  const showNotification = (message, type) => {
    toast(message, { type });
  };

  const accessToken = process.env.REACT_APP_API_TOKEN;

  const handleSendClick = async (e) => {
    e.preventDefault();
  
    if (!check) {
      console.error('Please accept the terms and conditions.');
      return;
    }
  
    const formDataToSend = new FormData();
    formDataToSend.append('title', title);
    formDataToSend.append('count', count);
  
    try {
      const response = await axios.post('https://back.frigidercomunitar.md/pay/payment/', formDataToSend, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Обработка успешного ответа от вашего бэкэнда
      console.log(response.data);

      setPaymentData(response.data);

    
    } catch (error) {
      showNotification('Что-то пошло не так:', 'error');
    }
  };
  

  return (
    <div>

      {paymentData && <Paynet paymentData={paymentData} />}

      <span onClick={openModal} className="main-content__product__cart-button put-set-to-cart" id="add-to-cart">
        <button className="btn-bay" id="btn" >оплатить</button>
      </span>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Adaugă Utilizator"
        shouldCloseOnOverlayClick={false}
        className="stype_upload_popup popup_height_auto"
      >
        <div className="contain_modal">
          <div className="modal-header">
            <h3 className="modal-title">Для оплаты необходимо согласиться с условиями конфиденциальности</h3>
            <button className="close" onClick={handleCloseModal}>×</button>
          </div>
          <div className="modal-body">
            <div className="modal__politic">
              <img style={{ maxHeight: '100px', maxWidth: '150px' }} src={logo} alt="Дари еду!" />
              <p style={{ fontWeight: 'bold' }}>Все продукты, купленные на нашем сайте попадают в руки нуждающихся.</p>
              <p className="modal_politic_description">Все продукты, купленные на нашем сайте попадают в руки нуждающихся. Мы помогаем одиноким пожилым людям, инвалидам, пенсионерам, ветеранам ВОВ, одиноким матерям, многодетным семьям, нетранспортабельным людям, бедным и просто голодным людям, которые в данный момент и день оказались в сложной жизненной ситуации.</p>
            </div>
            <div className={`main_cart__offert__action select_none ${check ? 'active' : ''}`}>
              <input
                id="accept-offer-conf"
                type="checkbox"
                className="main-cart__offert__action__checkbox set-cart-offert"
                name="accept-offer-conf"
                required=""
                onChange={handleCheckChange}
                checked={check}
              />
              <label htmlFor="accept-offer-conf" className="main_cart__offert__action__label">
                Я соглашаюсь с{' '}
                <Link className="main-registration__accepts__link" to="/politic">
                  Политикой конфиденциальности
                </Link>
              </label>
            </div>
            <div className={`select_none ${check ? 'active' : ''}`}>
              <button type="submit" className="but-grey" onClick={handleSendClick}>Отправить</button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default PopupCart;
